/* Component Dependencies */
var cardMediaTemplate = require('templates/cardMedia.hbs');
var cardMediaGrid4Template = require('templates/cardMedia-grid4.hbs');
var cardMediaSocialIconsTemplate = require('templates/cardMedia-socialIcons.hbs');
var cardMediaVideoTemplate = require('templates/cardMedia-video.hbs');
var AriesComponent = require('libs/aries-component');
var mobileFirstPicture = require('content_libs/mobileFirstPicture');
var ResponsiveUtils = require('utils/responsiveUtils');
var $ = require('jquery');

AriesComponent.create({
  type: 'cardMedia',
  template: {
    'cardMedia': cardMediaTemplate,
    'cardMedia-grid4': cardMediaGrid4Template,
    'cardMedia-socialIcons': cardMediaSocialIconsTemplate,
    'cardMedia-video': cardMediaVideoTemplate
  },
  bindEvents: function() {
    var _self = this;
    mobileFirstPicture.init(_self);
  }
});
