/*
Built to be applied to cardArticles and cardMedia
this sets the srcset for picture for desktop and tablet
based on the image container size.

By default srcset for large and tablet is set to be small image size
with data-srcset-large and data-srcset-tablet defined

When the image container is found to be large enough, srcset is changed
*/
var $ = require('jquery');
var Constants = require('libs/constants');
var ResponsiveUtils = require('utils/responsiveUtils');
var mobileFirstPicture = {
    changeSrcSet:function(el) {
        var _self = el || this;
        var viewport = this.getViewPort(this);
        var $srcSet = _self.$el.find('[data-srcset-'+ viewport + ']');
        var srcSetValue,$picture,imgWidth,$imageSrcSet;
        $srcSet.each(function(index,srcSet){
            $imageSrcSet = $(srcSet);
            srcSetValue = $imageSrcSet.data('srcset-' + viewport );
            $picture = $imageSrcSet.closest('picture');
            imgWidth = $picture.parent().width();
            if ( $imageSrcSet.length > 0 ) {
                if( imgWidth <= Constants.GLOBAL.MOBILE_VIEWPORT ){
                    param = $picture.data('param-mobile');
                
                } else if ( imgWidth > Constants.GLOBAL.MOBILE_VIEWPORT && imgWidth <= Constants.GLOBAL.TABLET_VIEWPORT ) {
                    param = $picture.data('param-tablet');

                } else if ( imgWidth > Constants.GLOBAL.TABLET_VIEWPORT && imgWidth <= Constants.GLOBAL.DESKTOP_VIEWPORT ) {
                    param = $picture.data('param-desktop');

                } else if ( imgWidth > Constants.GLOBAL.DESKTOP_VIEWPORT  ) {
                    param = $picture.data('param-large');
                }
                $imageSrcSet.attr( 'srcset', srcSetValue.replace('_queryParam_',param) );
            }
        })
        
    },
    getViewPort: function(that) {
        if (that.responsiveUtils.isMobileDevice) {
            return 'mobile';
        } else if (that.responsiveUtils.isDesktopDevice ) {
            return 'desktop';
        } else if (that.responsiveUtils.isTabletDevice) {
            return 'tablet';
        }
    },
    init:function(el) {
        var _self = this;
        console.log(this)
        _self.responsiveUtils = ResponsiveUtils.create({
            orientationChangeEventHandler: function(){_self.changeSrcSet(el)},
            resizeEventHandler: function(){_self.changeSrcSet(el)}
        });
       _self.responsiveUtils.init();
        
        this.changeSrcSet(el);
    }
}
module.exports = mobileFirstPicture;
    
    