var $ = require('jquery');
var _ = require('lodash');
var Constants = require('libs/constants');
var ComponentMapper = require('libs/component-mapper');

var responsiveUtils = {
  init: function() {
    var _self = this;
    this.setDeviceInformation();
    $(window).on("resize",function() {
      _self.setDeviceInformation();
      if (_.isFunction(_self.resizeEventHandler)) {
        _self.resizeEventHandler();
      }
    });
    $(window).on("orientationchange",function() {
      setTimeout(function() {
        _self.setDeviceInformation();
        if (_.isFunction(_self.orientationChangeEventHandler)) {
          _self.orientationChangeEventHandler();
        }
      })
    });
  },
  setDeviceInformation: function() {
    var _self = this;
    var viewport = this.getViewportConfig();

    var windowWidth = $(window).width();
    _self.isTabletDevice = _self.isMobileDevice = _self.isDesktopDevice = false;
    if(windowWidth >= viewport.TABLET_VIEWPORT && windowWidth <= viewport.DESKTOP_VIEWPORT) {
      _self.isTabletDevice = true;
    } else if(windowWidth < viewport.TABLET_VIEWPORT){
      _self.isMobileDevice = true;
    } else {
      _self.isDesktopDevice = true;
    }
  },
  /**
  * This function is used to identify device is tablet or not
  * @returns {boolean} true or false if device is tablet or not
  */
  isTablet: function() {
      return this.isTabletDevice;
  },
  /**
  * This function is used to identify device is mobile or not
  * @returns {boolean} true or false if device is mobile or not
  */
  isMobile: function() {
      return this.isMobileDevice;
  },
  /**
  * This function is used to identify device is desktop or not
  * @returns {boolean} true or false if device is desktop or not
  */
  isDesktop: function() {
      return this.isDesktopDevice;
  },
  /**
  * This function is used to identify device is mobile or tablet
  * @returns {boolean} true or false if device is mobile or tablet
  */
  isMobileOrTablet: function() {
      if(typeof this.isTakeOverRequired !== 'undefined') {
        return false;
      }
      return this.isMobileDevice || this.isTabletDevice;
  },
  /**
  * This function is used to identify device orientation
  * @returns {boolean} device orientation is portrait or landscape
  */
  isPortrait: function() {
      return window.innerHeight > window.innerWidth;
  },
  /**
  * This function is used to identify device/desktop
  * @returns {boolean} device orientation is portrait or landscape
  */
  isDevice: function() {
    if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
      return true;
    }
    return false;
  },

  /**
   * Returns tablet and desktop viewport config values
   */
  getViewportConfig: function() {
    var viewport = this.viewport || Constants.GLOBAL;
    return {
      TABLET_VIEWPORT: viewport.TABLET_VIEWPORT || Constants.GLOBAL.TABLET_VIEWPORT,
      DESKTOP_VIEWPORT: viewport.DESKTOP_VIEWPORT || Constants.GLOBAL.DESKTOP_VIEWPORT
    };
  }
};

module.exports = {
  /**
  * This function is used to create instance of takeover screen
  */
  create: function(props) {
    var obj = _.cloneDeep(responsiveUtils);
    var ResponsiveUtilsInstance = ComponentMapper.extend(obj);
    return new ResponsiveUtilsInstance(props);
  }
}
